// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]7.0-11504(67523f24bb)-C09/04/2025-19:40:45-B09/04/2025-19:42:48' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]7.0-11504(67523f24bb)-C09/04/2025-19:40:45-B09/04/2025-19:42:48",
  branch: "production",
  latestTag: "7.0",
  revCount: "11504",
  shortHash: "67523f24bb",
  longHash: "67523f24bbff90fbf062139f44cb3ff382f7f05e",
  dateCommit: "09/04/2025-19:40:45",
  dateBuild: "09/04/2025-19:42:48",
  buildType: "Ansible",
  } ;
