import {Component, OnInit} from "@angular/core";
import {AdministrationService} from "../../core/services/gestion-administration/administration.service";
import {MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {ToastService} from "../../core/services/technique/toast.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'yo-gestion-administration',
  templateUrl: './gestion-administration.component.html',
  styleUrls: ['./gestion-administration.component.scss']
})
export class GestionAdministrationComponent implements OnInit {

  popupPurgeVisible: boolean = false;
  popupAllergenesVisible: boolean = false;

  dialogTitle: string;

  constructor(private administrationService: AdministrationService,
              private title: Title,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initData();
  }

  initData = (): void => {
    this.title.setTitle(this.getTitle());
    this.dialogTitle = 'Confirmation';
  }

  getTitle = (): string => "GESTION ADMINISTRATION";

  purgeInactiveArticles = async (): Promise<void> => {
    this.administrationService.purgeInactiveArticles()
      .subscribe(response => {
        this.closeDialogPurge();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La suppression a été réalisée avec succès`);
      })
  }

  majAllergenes = async (): Promise<void> => {
    this.administrationService.majAllergenes()
      .subscribe(response => {
        if(response.one) {
          this.closeDialogAllergenes();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La mise à jour a été réalisée avec succès`);
        }
      })
  }

  showPopupPurge = () => this.popupPurgeVisible = true;
  showPopupAllergenes = () => this.popupAllergenesVisible = true;

  closeDialogPurge = () => this.popupPurgeVisible = false;
  closeDialogAllergenes = () => this.popupAllergenesVisible = false;
}
